const DEFAULT_PRODUCT_VERTICAL = 'nutrition';
const DEFAULT_PRODUCT_TYPE = 'menopause';

export const getProductVerticalAndTypeByProductHandler = () => ({
  execute: ({ product }) => {
    if (!product?.productType) {
      return { vertical: DEFAULT_PRODUCT_VERTICAL, type: DEFAULT_PRODUCT_TYPE };
    }

    const productTypeArray = product.productType.split('-');
    const productVertical = productTypeArray.shift();
    const productType = productTypeArray.join('-');

    return { vertical: productVertical, type: productType };
  },
});
