import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE,
  includedRoutes: ['/tour/nutrition-monthly-start', '/tour/nutrition-monthly-start/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      path: `/tour/${QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY}`,
    },
    {
      tour: QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V1,
      path: `/tour/${QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V1}`,
    },
    {
      tour: QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V2,
      path: `/tour/${QUIZ_VARIANTS.NUTRITION_MONTHLY_START_LP_WITH_BELLY_IMAGE_V2}`,
    },
  ],
};
