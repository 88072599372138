import { REGIONS } from '@/services/constants';

const LOCALHOST_URL = 'localhost:3000';
const QUIZ_START_PATH = '/quiz/start';

const isValidRegion = (region) => Object.keys(REGIONS).includes(region);

const isUSRegion = (region) => region.toUpperCase() === REGIONS.us;

const isLocalhost = (req) => {
  const host = req?.headers?.host || '';
  return host.includes('localhost');
};

const generateHost = (region, req) => {
  const BASE_HOST = isLocalhost(req) ? LOCALHOST_URL : process.env.DOMAIN;
  return isUSRegion(region) ? BASE_HOST : `${region}.${BASE_HOST}`;
};

export default function ({ route, redirect, req }) {
  const { region, ...otherParams } = route.query;

  if (!region || !isValidRegion(region)) return;

  if (route.path.includes(QUIZ_START_PATH)) {
    const host = generateHost(region, req);
    const queryString = new URLSearchParams(otherParams).toString();
    const fullUrl = queryString ? `${host}${route.path}?${queryString}` : `${host}${route.path}`;
    const protocol = isLocalhost(req) ? 'http' : 'https';

    return redirect(`${protocol}://${fullUrl}`);
  }
}
