import {
  SET_QUIZ_TOTAL_LENGTH,
  SET_QUIZ_INDEX,
  SET_QUIZ_ANSWERS,
  SET_QUIZ_LIST,
  SET_HEADER_STATUS,
  SET_USER_ATTRS_EMAIL,
  SET_USER_ATTRS_FINAL_WEIGHT,
  SET_INTERCOM_ID,
  SET_TARGET_LOSS,
  SET_QUIZ_VARIANT,
  SET_LANDING_PAGE_ID,
  SET_QUIZ_REFERRER_LINK,
  SET_QUIZ_STEP_FROM,
  SET_QUIZ_STEP_TO,
  SET_DEFAULT_STATE_VALUE_LANDING,
  SET_LEAD_USER_REFERRER_LINK,
  SET_PRODUCT_TYPE,
  SET_PRODUCT,
} from '../mutation-types';
import { getUtmObject } from '../../services/utm';
import URLS from '@/services/urls';
import { Helpers } from '@/services/helpers';
import { ABTestingNuxt } from '@/services/ab-testing';
import { QUIZ_VARIANTS } from '@/services/constants';
import { getLoggerService } from '@/services/logger';

const { logger } = getLoggerService();

/**
 *  Set quiz list from en-EN.json file
 * @param {function} commit - The vuex commit function
 * @param {Array} payload - Quiz list
 * @return {Array} Quiz list
 */
export function setQuizListAction({ commit }, payload) {
  commit(SET_QUIZ_LIST, payload);
}

/**
 *  Set quiz total length according to the number of question we have
 *  in the en-EN.json file
 * @param {function} commit - The vuex commit function
 * @param {Number} payload - Quiz total length
 * @return {Number} Quiz total length value
 */
export function setQuizTotalLengthAction({ commit }, payload) {
  commit(SET_QUIZ_TOTAL_LENGTH, payload);
}

/**
 *  Set quiz current index and it will show the question number on each interaction
 * @param {function} commit - The vuex commit function
 * @param {Number} payload - Quiz current index number
 * @return {Number} Quiz total length index number value
 */
export function setQuizCurrentIndexAction({ commit }, payload) {
  commit(SET_QUIZ_INDEX, payload);
}

/**
 *  Push the value for each quiz question to store then in Array
 * @param {function} commit - The vuex commit function
 * @param {String[]|String} payload - Quiz answer it can be single value or array of values
 * @return {String[]|String} Quiz answer values
 */
export function pushQuizAnswerToTheStoreAction({ commit }, payload) {
  commit(SET_QUIZ_ANSWERS, payload);
}

/**
 *  Set header status according to giving condition
 * @param {function} commit - The vuex commit function
 * @param {Boolean} payload - Header Status according to giving condition
 * @return {Boolean} Header Status
 */
export function setHeaderStatusAction({ commit }, payload) {
  commit(SET_HEADER_STATUS, payload);
}

export async function setProductType({ rootGetters, commit }, tourId) {
  const selectedTourId = tourId ?? rootGetters['quiz/getQuizVariant'];

  const tourIdProductTypeItem = this.$productTypes.find((item) => String(item.tourId) === String(selectedTourId));

  if (!tourIdProductTypeItem) {
    logger.error(`tourId not mapped to productType. Returned the fallback value`, {
      tourId: selectedTourId,
    });
    return;
  }

  commit(SET_PRODUCT, tourIdProductTypeItem);
  commit(SET_PRODUCT_TYPE, tourIdProductTypeItem.productType);
}

/**
 *  Send all quiz answers to firebase API
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @return {Any} Firebase response
 */
export async function sendQuizAnswersToFirebaseAction({ state, rootGetters, dispatch }, payload) {
  try {
    await dispatch('detected-country/fetchDetectedCountryAction', null, {
      root: true,
    });
  } catch (e) {
    console.error(e);
    this.$sentry.captureException(e);
  }

  const { answers, userAttrs } = state;

  const mappedAnswers = answers.map((el) => {
    return el.selectedAttribute
      ? { [el.id]: { ...el.answer, selectedAttribute: el.selectedAttribute } }
      : { [el.id]: el.answer };
  });
  const quizAnswersObject = Object.assign({}, ...mappedAnswers);
  const experiments = ABTestingNuxt.getUserExperimentsFromCookies() || {};
  const landingPagePath = rootGetters['quiz/getQuizReferrerLink'];
  const quizReferrer = rootGetters['quiz/getLeadUserReferrerLink'];
  const quizId = rootGetters['quiz/getQuizVariant'];
  const utmParams = getUtmObject();
  const hasUtmParams = Object.keys(utmParams).length > 0;
  const productType = rootGetters['quiz/getProductType'];

  const apiDataObject = {
    quiz: quizAnswersObject,
    productType,
    checkoutVariation: state?.product?.checkoutVariation || null,
    pricingModel: state?.product?.pricingModel.toLowerCase() || null,
    userAttrs: {
      ...userAttrs,
      region: Helpers.getCountryFromUrl(),
      language: payload.language,
      host: this.$host,
      geo: this.$geo,
      hasDeniedMktComms: hasUtmParams ? null : payload.hasDeniedMktComms,
      appVersion: this.$config.appVersion,
    },
    utmParams,
    experiments,
    landingPagePath,
    quizReferrer,
    experience: {
      landingPageId: rootGetters['quiz/getLandingPageId'] || 0,
      quizId: parseInt(quizId || QUIZ_VARIANTS.DEFAULT),
    },
  };

  dispatch('have-lead/setLeadExperiments', experiments, { root: true });

  try {
    const data = await this.$api.$post(URLS.GENERATE_LEAD, apiDataObject);

    dispatch('have-lead/setLeadUUID', data.uuid, {
      root: true,
    });

    if (quizId !== QUIZ_VARIANTS.MEDS) {
      await dispatch('checkout/fetchCheckoutId', null, { root: true });
    }

    await dispatch(
      'logger/logEventWithQuizMeta',
      {
        message: 'Lead created',
        tag: 'lead-created-log',
      },
      {
        root: true,
      },
    );

    return data;
  } catch (error) {
    let errorMessage = '';
    let errorCode = null;
    let errorStatusCode = null;

    if (error.response) {
      errorMessage = `Error: ${error.response.data.message} - Status code: ${error.response.status}`;
      errorCode = error.response.data.code;
      errorStatusCode = error.response.status;
    } else if (error.request) {
      errorMessage = error;
    } else {
      errorMessage = error.message;
    }

    return Promise.reject({ message: errorMessage, code: errorCode, statusCode: errorStatusCode });
  }
}

/**
 * Updates the lead's quiz answers in Firebase.
 *
 * This action takes the quiz answers from the Vuex store, formats them into a
 * structured object, and sends an API request to update the lead's data in Firebase.
 * In case of errors during the API request, it handles the error and returns a
 * rejected Promise with detailed error information.
 *
 * @param {Object} context - The Vuex action context.
 * @param {Object} context.state - The Vuex state object containing quiz answers and user data.
 * @param {Object} context.rootGetters - The root Vuex getters for accessing data from other modules.
 * @param {Object} payload (optional) - Any additional payload data (not used in this implementation).
 *
 * @returns {Promise} A Promise that resolves when the update is successful or rejects with an error object
 * containing `message`, `code`, and `status` properties.
 */
export async function updateLeadQuizInFirebaseAction({ state, rootGetters }, payload) {
  const {
    answers,
    userAttrs: { email },
  } = state;
  const leadUUID = rootGetters['have-lead/getLeadUUID'];

  const mappedAnswers = answers.map((el) => {
    return el.selectedAttribute
      ? { [el.id]: { ...el.answer, selectedAttribute: el.selectedAttribute } }
      : { [el.id]: el.answer };
  });
  const quizAnswersObject = Object.assign({}, ...mappedAnswers);

  try {
    await this.$api.$put(URLS.UPDATE_LEAD(leadUUID), { email, quiz: quizAnswersObject });
  } catch (error) {
    const errorMessage = error.response
      ? `Error: ${error.response.data.message} - Status code: ${error.response.status}`
      : error.message || 'Unknown error';

    return Promise.reject({
      message: errorMessage,
      code: error.response?.data?.code || null,
      status: error.response?.status || null,
    });
  }
}

/**
 *  Set user attrs email value
 * @param {function} commit - The vuex commit function
 * @param {String} payload - User email value
 * @return {String} User email value
 */
export function setUserAttrsEmailAction({ commit }, payload) {
  commit(SET_USER_ATTRS_EMAIL, payload);

  return Helpers.resolvePromise(payload);
}

/**
 *  Set user attrs final weight value
 * @param {function} commit - The vuex commit function
 * @param {Number|String} payload - User attrs final weight value
 * @return {Number|String} User attrs final weight value
 */
export function setUserAttrsFinalWeightAction({ commit }, payload) {
  commit(SET_USER_ATTRS_FINAL_WEIGHT, payload);
}

/**
 *  Set Intercom id value
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Intercom id value
 * @return {String} Intercom id value
 */
export function setIntercomIdAction({ commit }, payload) {
  commit(SET_INTERCOM_ID, payload);
}

/**
 *  Set target loss value for more info check {quiz-achievable-weight.vue}
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Target loss value
 * @return {String} Target loss value
 */
export function setTargetLossAction({ commit }, payload) {
  commit(SET_TARGET_LOSS, payload);
}

export function setQuizVariantAction({ commit, dispatch }, payload) {
  const stringPayload = typeof payload === 'number' ? payload.toString() : payload;
  commit(SET_QUIZ_VARIANT, stringPayload);
  dispatch('setProductType', stringPayload);
}

export function setLandingPageIdAction({ commit }, payload) {
  commit(SET_LANDING_PAGE_ID, payload);
}

/**
 *  Set quiz Referrer link
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Quiz referrer link
 * @return {string} Quiz referrer link
 */
export function setQuizReferrerLinkAction({ commit }, payload) {
  const aUrls = [
    '/',
    '/over40',
    '/tour',
    '/tour/cholesterol-control',
    '/tour/fast-weight-loss-for-women',
    '/tour/lean-body',
    '/tour/best-body',
    '/tour/pilates',
    '/tour/wall-pilates',
    '/tour/pilates-monthly',
    '/tour/pilates-now',
    '/tour/pilates-now-monthly',
    '/tour/body-28-day-challenge',
    '/tour/pilates-28-day-challenge',
    '/tour/pilates-28-day-chair-challenge',
    '/tour/pilates-28-day-flat-belly',
    '/tour/pilates-28-day-challenge-monthly',
    '/tour/body-nutrition',
    '/tour/macro-diet-for-women',
    '/tour/metabolic-renewal-for-women',
    '/tour/optimal-weight-program',
    '/tour/weight-loss',
    '/tour/weight-loss-over-40',
    '/tour/weight-loss-secret',
    '/tour/weight-loss-without-fasting',
    '/tour/womens-health-food-impact',
    '/tour/nutrition-monthly-start',
    '/tour/nutrition-keto',
    '/tour/nutrition-keto-weekly',
    '/tour/nutrition-keto-age',
    '/tour/nutrition-28-day-sugar-challenge',
    '/tour/nutrition-28-day-wl-challenge',
    '/tour/nutrition-weekly-start',
    '/tour/nutrition-keto-meal',
    '/tour/meds',
    '/tour/yoga-28-day-challenge',
    '/tour/yoga-menopause-challenge',
    '/tour/nutrition-keto-weekly-age',
    '/tour/pilates-somatic-28-day-challenge',
    '/tour/nutrition-keto-meal-weekly',
    '/tour/somatic-yoga',
    '/tour/somatic-exercises',
    '/tour/nutrition-monthly-generic-us',
    '/tour/nutrition-monthly-generic-others',
    '/tour/pilates-28-day-challenge-generic-us',
    '/tour/pilates-28-day-challenge-generic-others',
    /^\/tour\/\d+$/,
  ];

  const removeLastSlash = (url) =>
    url.substring(url.length - 1, url.length) === '/' ? url.substring(0, url.length - 1) : url;

  aUrls.forEach((url) => {
    if (url instanceof RegExp) {
      if (url.test(removeLastSlash(payload))) {
        commit(SET_QUIZ_REFERRER_LINK, payload);
      }
    } else if (removeLastSlash(payload) === removeLastSlash(url)) {
      commit(SET_QUIZ_REFERRER_LINK, url);
    }
  });
}
/**
 *  Set quiz_referrer  from when user perform a history.back of history.forward
 * @param {function} commit - The vuex commit function
 * @param {String} payload - quiz_referrer  from string
 * @return {String} quiz_referrer  from String
 */
export function setLeadUserReferrerLink({ commit }, payload) {
  commit(SET_LEAD_USER_REFERRER_LINK, payload);
}
/**
 *  Set quiz step from when user perform a history.back of history.forward
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Quiz step from string
 * @return {String} Quiz step from String
 */
export function setQuizStepFrom({ commit }, payload) {
  commit(SET_QUIZ_STEP_FROM, payload);
}

/**
 *  Set quiz step to when user perform a history.back of history.forward
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Quiz step to string
 * @return {String} Quiz step to String
 */
export function setQuizStepTo({ commit }, payload) {
  commit(SET_QUIZ_STEP_TO, payload);
}
/**
 *  Set quiz step to when user perform a history.back of history.forward
 * @param {function} commit - The vuex commit function
 * @param {String} payload - Quiz step to string
 * @return {String} Quiz step to String
 */
export function setDefaultStateValue({ commit }, payload) {
  commit(SET_DEFAULT_STATE_VALUE_LANDING, payload);
}
