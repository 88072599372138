import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_CHECKOUT_TEST_DIFFERENT_TITLE,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  allocateOnClient: true,
  variants: [
    {
      expVariant: null,
    },
    {
      expVariant: '4377-1',
    },
    {
      expVariant: '4377-2',
    },
  ],
};
