import URLS from '@/services/urls';

import { SET_MEDS_SHIPPING, SET_ALREADY_EXISTING_IVIM_ACCOUNT } from '../mutation-types';

export async function getRedirectIvimToken({ rootGetters, dispatch }) {
  dispatch('payment/setComponentName', 'redirect-loader', {
    root: true,
  });

  const email = rootGetters['payment/getUserEmail'] ?? '';
  const uuid = rootGetters['have-lead/getLeadUUID'] ?? '';

  const encodedEmail = encodeURIComponent(email);

  try {
    const { accessToken: token } = await this.$api.$get(URLS.GET_REDIRECT_TO_IVIM_TOKEN({ email: encodedEmail, uuid }));
    if (token) {
      redirectToIvim(token);
    } else {
      throw new Error('Error recovering IVIM token');
    }
  } catch (err) {
    this.$sentry.captureException(err);
    dispatch('payment/setComponentName', 'mail-instructions', {
      root: true,
    });
  }
}

export async function sendUserDetailsToIVIM({ state }, payload) {
  try {
    await this.$api.$post(URLS.SEND_USER_DETAILS_TO_IVIM, { ...payload });
  } catch (err) {
    this.$sentry.captureException(err);
  }
}

export async function checkAccountExistsInIvim({ commit }, email) {
  try {
    const checkIVIMAccount = await this.$api.$post(URLS.CHECK_USER_EXIST_IN_IVIM, { email });
    if (checkIVIMAccount.exists) {
      commit(SET_ALREADY_EXISTING_IVIM_ACCOUNT, checkIVIMAccount.exists);
    }
  } catch (err) {
    this.$sentry.captureException(err);
  }
}

export function redirectToIvim(token) {
  const ivimUrl = `${URLS.IVIM_BASE_URL}?rest_route=/token-login/v1/autologin&JWT=${token}&redirectUrl=https://www.ivimhealth.com/weightloss-quiz`;
  window.location = ivimUrl;
}

export function setMedsShippingInfo({ commit }, payload) {
  commit(SET_MEDS_SHIPPING, payload);
}
