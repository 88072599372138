import * as actions from './actions';
import { SET_MEDS_SHIPPING, SET_ALREADY_EXISTING_IVIM_ACCOUNT } from '../mutation-types';

const mutations = {
  [SET_MEDS_SHIPPING](state, payload) {
    state.shippingInfo = payload;
  },
  [SET_ALREADY_EXISTING_IVIM_ACCOUNT](state, payload) {
    state.alreadyExistingIvimAccount = payload;
  },
};

const state = () => ({
  shippingInfo: {},
  alreadyExistingIvimAccount: false,
});

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
