import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PAYMENT_ERROR_PERSONALIZED_MESSAGES,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/authority-related-testimonials-body-nutrition',
    '/quiz/authority-related-testimonials-body-nutrition/',
    '/quiz/program-benefits-2',
    '/quiz/program-benefits-2/',
    '/quiz/ready-to-commit',
    '/quiz/ready-to-commit/',
    '/quiz/authority-related-testimonials-keto',
    '/quiz/authority-related-testimonials-keto/',
    '/quiz/healthy-weight-after-glp',
    '/quiz/healthy-weight-after-glp/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
    '/quiz/ready-to-commit-yoga',
    '/quiz/ready-to-commit-yoga/',
    '/quiz/ready-to-commit-yoga-menopause',
    '/quiz/ready-to-commit-yoga-menopause/',
  ],
  allocateOnClient: true,
  variants: [
    {
      expVariant: null,
    },
    {
      expVariant: '3974-1',
    },
  ],
};
