import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES,
  includedRoutes: [
    '/tour/pilates-28-day-challenge',
    '/tour/pilates-28-day-challenge/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT}`,
      expVariant: null,
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1}`,
      expVariant: '4422-1',
    },
    {
      tour: QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
      path: `/tour/${QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2}`,
      expVariant: '4422-2',
    },
  ],
};
