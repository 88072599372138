import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.YOGA_LP_NO_CHALLENGE,
  includedRoutes: ['/tour/yoga-28-day-challenge', '/tour/yoga-28-day-challenge/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE}`,
    },
    {
      tour: QUIZ_VARIANTS.YOGA_LP_NO_CHALLENGE_V1,
      path: `/tour/${QUIZ_VARIANTS.YOGA_LP_NO_CHALLENGE_V1}`,
    },
    {
      tour: QUIZ_VARIANTS.YOGA_LP_NO_CHALLENGE_V2,
      path: `/tour/${QUIZ_VARIANTS.YOGA_LP_NO_CHALLENGE_V2}`,
    },
  ],
};
