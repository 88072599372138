// import pricing from './pricing';
// import pricingLatam from './pricing-latam';
// import offerPageCheckoutUpdatedPricePoints from './offer-page-checkout-updated-price-points';
// import quizCompareCurrentControlVsControl3MonthsAgo from './quiz-compare-current-control-vs-control-3-months-ago';
import dummyOfferTest from './dummy-offer-test';
import dummyTourTest from './dummy-tour-test';
// import pricingTelcoUS from './pricing-telco-us-dev-2337';
// import pricingDevenDollarTrial from './pricing-seven-dollar-trial';
import pricingBrEbanx from './pricing-br-ebanx';
// import tourGlpOne from './tour-glp-one';
// import quizCheckoutGlp1IvimHealth from './quiz-checkout-glp1-ivim-health';
import quizNewLanderTest from './quiz-new-lander-test';
import pilates28DayChallengeNewCheckout from './pilates-28-day-challenge-new-checkout';
import pilates28DayChallengeNewSecondaryText from './pilates-28-day-challenge-new-secondary-text';
import applePayCheckoutDotCom from './apple-pay-checkout-dot-com';
import pricingPilatesWeekly from './pricing-pilates-weekly';
import pilatesChallangeNewDesign from './fitness-new-checkout-new-design';
import nutritionChallangeNewDesign from './nutrition-new-checkout-new-design';
import pilates28DayChallengeAddAnalysisStep from './pilates-28-day-challenge-add-analysis-step';
import pricingPilatesWeeklyNewCheckout from './pricing-pilates-weekly-new-checkout';
import paymentErrorPersonalizedMessages from './payment-error-personalized-messages';
import applePayButtonText from './apple-pay-button-text';
import quizImagesPerformance from './quiz-images-performance';
import reloadModal from './reload-modal';
import yoga28DayChallengeRemoveAthleticType from './yoga-28-day-challenge-remove-athletic-type';
import yoga28DayChallengeLandingPageSenior from './yoga-28-day-challenge-landing-page-senior';
import yogaLpNoChallenge from './yoga-lp-no-challenge';
import pilates28DayChallengeFullBodyBeforeAfterImages from './pilates-28-day-challenge-full-body-before-after-images';
import nutritionMonthlyStartLpWithBellyImage from './nutrition-monthly-start-lp-with-belly-image';
import fitnessPilatesSecretGiftMessage from './fitness-pilates-secret-gift-message';
import pilates28DayChallengeCheckoutTestDifferentTitle from './pilates-28-day-challenge-checkout-test-different-title';
import pilates28DayChallengeUpdatedTargedZoneStep from './pilates-28-day-challenge-updated-targed-zone-step';

const tests = [
  // Pending to be cleaned up
  // pricing,
  // pricingLatam,
  // quizLandingPage,
  // pricingTelcoUS,
  // tourGlpOne,
  // pricingDevenDollarTrial,
  // quizCheckoutGlp1IvimHealth,

  // Experiments stopped and pending to make a decision
  // offerPageCheckoutUpdatedPricePoints, // Wait some months until we make a conclusion
  // pricingTelcoUS,
  // pricingDevenDollarTrial,
  // tourGlpOne,

  // Dummy experiments
  dummyOfferTest,
  dummyTourTest,

  // Before launching these ones, they require adjustments

  // Ready to launch via config file
  pricingBrEbanx,
  quizNewLanderTest,
  pilates28DayChallengeNewSecondaryText,
  pilates28DayChallengeAddAnalysisStep,
  // New checkout experiments
  pilates28DayChallengeNewCheckout,
  applePayCheckoutDotCom,
  pricingPilatesWeekly,
  pilatesChallangeNewDesign,
  nutritionChallangeNewDesign,
  pricingPilatesWeeklyNewCheckout,
  paymentErrorPersonalizedMessages,
  applePayButtonText,
  quizImagesPerformance,
  reloadModal,
  yoga28DayChallengeRemoveAthleticType,
  yoga28DayChallengeLandingPageSenior,
  yogaLpNoChallenge,
  pilates28DayChallengeFullBodyBeforeAfterImages,
  nutritionMonthlyStartLpWithBellyImage,
  fitnessPilatesSecretGiftMessage,
  pilates28DayChallengeCheckoutTestDifferentTitle,
  pilates28DayChallengeUpdatedTargedZoneStep,
];

export default tests;
