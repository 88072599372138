import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.APPLE_PAY_BUTTON_TEXT,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/pilates-ready-to-commit',
    '/quiz/pilates-ready-to-commit/',
    '/quiz/ready-to-commit',
    '/quiz/ready-to-commit/',
    '/quiz/authority-related-testimonials-body-nutrition',
    '/quiz/authority-related-testimonials-body-nutrition/',
  ],
  allocateOnClient: true,
  variants: [
    {
      expVariant: null,
    },
    {
      expVariant: '4164-1',
    },
  ],
};
