import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.QUIZ_IMAGES_PERFORMANCE,
  includedRoutes: ['/tour', '/tour/', '/tour/nutrition-monthly-start', '/tour/nutrition-monthly-start/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
      path: `/tour/${QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY}`,
    },
    {
      tour: QUIZ_VARIANTS.QUIZ_IMAGES_PERFORMANCE_EXPERIMENT,
      path: `/tour/${QUIZ_VARIANTS.QUIZ_IMAGES_PERFORMANCE_EXPERIMENT}`,
    },
  ],
};
