const WHITELISTED_DOMAINS = {
  google: 'https://google.com https://*.google.com',
  cloudflare: 'https://*.cloudflareinsights.com  https://*.cloudflare.com',
  braintree: 'https://*.braintree-api.com https://*.braintreegateway.com',
  datadog: 'https://browser-intake-datadoghq.eu',
  gtm: 'https://www.googletagmanager.com',
  googleFonts: 'https://fonts.googleapis.com https://fonts.gstatic.com',
  kv: 'https://staging-kv-config-ab-testing.reversegroupio.workers.dev/ https://kv-config-ab-testing.reversegroupio.workers.dev/',
};

export const cspHeader = `
    default-src 'self' ${WHITELISTED_DOMAINS.google};
    script-src 'self' 'unsafe-eval' 'unsafe-inline' ${WHITELISTED_DOMAINS.google} ${WHITELISTED_DOMAINS.cloudflare} ${WHITELISTED_DOMAINS.gtm} ${WHITELISTED_DOMAINS.braintree};
    style-src 'self' 'unsafe-inline' ${WHITELISTED_DOMAINS.googleFonts};
    img-src 'self' blob: data: https://reverse.health https://staging.reverse.health https://staging-static.reverse.health;
    font-src 'self' ${WHITELISTED_DOMAINS.googleFonts};
    object-src 'none';
    base-uri 'self';
    form-action 'self';
    frame-ancestors 'none';
    connect-src 'self' ${WHITELISTED_DOMAINS.google} ${WHITELISTED_DOMAINS.braintree} ${WHITELISTED_DOMAINS.datadog} ${WHITELISTED_DOMAINS.kv};
`
  .replace(/\s{2,}/g, ' ')
  .trim();

export default function (context) {
  const excludeRoutes = /^\/(_nuxt\/static|_nuxt\/image|api|favicon\.ico)/;

  if (process.server && !excludeRoutes.test(context.route.path)) {
    context.res.setHeader('Content-Security-Policy-Report-Only', cspHeader);
  }
}
