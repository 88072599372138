const QUIZ_REGEX = /^\/quiz\/.*/;

export default function ({ store, redirect, route }) {
  if (process.client) {
    const { path } = route;

    if (QUIZ_REGEX.test(path)) {
      const tourUrl = store?.state?.quiz?.quizReferrerLink;

      if (!tourUrl) {
        const lastTour = window.sessionStorage.getItem('tourUrl');

        if (lastTour) {
          redirect(lastTour);
        }
      } else {
        window.sessionStorage.setItem('tourUrl', tourUrl);
      }
    }
  }
}
