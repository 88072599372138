export default async function (context, inject) {
  let jsonData = null;
  try {
    const response = await context.$axios.get(context.$config.abTestingKv);
    jsonData = response.data;

    inject('kvExperiments', Array.isArray(jsonData) ? jsonData : []);
  } catch (e) {
    let errorMessage = 'Unknown error occurred';
    if (e.response) {
      errorMessage = {
        message: `Server responded with status ${e.response.status}: ${e.response.statusText}`,
        error: e.response,
      };
    } else if (e.request) {
      errorMessage = { message: 'No response received from server', error: e.request };
    } else {
      errorMessage = { message: 'Error in setting up request', error: e.message };
    }

    context.$sentry.captureException(new Error('Error fetching A/B testing KV data'), {
      extra: {
        rawData: jsonData,
        errorDetails: errorMessage,
      },
    });

    inject('kvExperiments', []);
  }
}
