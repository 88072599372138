import { removeUrlDoubleSlash } from '@/services/url';

export default function ({ route, redirect }) {
  if (route.fullPath.includes('//')) {
    const correctedPath = removeUrlDoubleSlash(route.fullPath);

    if (correctedPath !== route.fullPath) {
      return redirect(correctedPath);
    }
  }
}
