import {
  SET_CANCELLATION_NAVIGATION,
  FETCH_CANCELLATION_NAVIGATION,
  GET_SUBSCRIPTION_HISTORY,
  SET_SUBSCRIPTION_FETCHING,
  SET_SUBSCRIPTION_HISTORY_FETCHING,
  SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE,
  SET_REACTIVATE_SUBSCRIPTION_FETCHING,
  SET_SOURCE_APP,
  SET_UTM_OBJECT,
} from '../mutation-types';
import URLS from '@/services/urls';
import { Helpers } from '@/services/helpers';

export function setFetchNavigation({ commit }, payload) {
  commit(SET_SUBSCRIPTION_FETCHING, payload);
}

export async function setCancellationNavigation({ commit }, payload) {
  try {
    commit(FETCH_CANCELLATION_NAVIGATION, true);
    const data = (await this.$api.$get(URLS.GET_USER, payload)) || {};
    commit(SET_CANCELLATION_NAVIGATION, data);
    commit(FETCH_CANCELLATION_NAVIGATION, false);
  } catch (err) {
    this.$sentry.captureException(err);
    commit(FETCH_CANCELLATION_NAVIGATION, false);
  }
}
export async function getSubscriptions({ commit }) {
  try {
    commit(SET_SUBSCRIPTION_FETCHING, true);
    const subscription = await this.$api.$get(URLS.GET_SUBSCRIPTION_HISTORY);
    commit(GET_SUBSCRIPTION_HISTORY, subscription);
    commit(SET_SUBSCRIPTION_FETCHING, false);
  } catch (err) {
    this.$sentry.captureException(err);
    commit(SET_SUBSCRIPTION_FETCHING, false);
    console.log(err);
  }
}

export async function getSubscriptionsWithHistory({ commit }) {
  try {
    commit(SET_SUBSCRIPTION_HISTORY_FETCHING, true);
    const subscription = await this.$api.$get(URLS.GET_SUBSCRIPTION_HISTORY);
    commit(GET_SUBSCRIPTION_HISTORY, subscription);
    commit(SET_SUBSCRIPTION_HISTORY_FETCHING, false);
  } catch (err) {
    this.$sentry.captureException(err);
    commit(SET_SUBSCRIPTION_HISTORY_FETCHING, false);
    console.log(err);
  }
}

export async function resubscribeToPlan({ commit }, payload) {
  try {
    commit(SET_REACTIVATE_SUBSCRIPTION_FETCHING, true);
    const data = await this.$api.$post(URLS.REACTIVATE_SUBSCRIPTION, { email: payload });
    commit(SET_REACTIVATE_SUBSCRIPTION_FETCHING, false);
    return Helpers.resolvePromise(data);
  } catch (err) {
    this.$sentry.captureException(err);
    commit(SET_REACTIVATE_SUBSCRIPTION_FETCHING, false);
  }
}

export function setShowContentByTrafficSource({ commit }, payload) {
  try {
    const utmSource = payload?.utmSource;
    const allowedSources = ['google', 'bing', 'klaviyo', 'affiliate'];

    commit(SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE, false);

    // Facebook doesn't allow to show explicit images about weight loss
    // So by protection we only show them when is google, bing, klaviyo, affiliate
    // empty non utm_source
    if (allowedSources.includes(utmSource) || !utmSource) {
      commit(SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE, true);
    }
  } catch (err) {
    this.$sentry.captureException(err);
    commit(SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE, false);
    console.log(err);
  }
}

export function setSourceApp({ commit }, payload) {
  commit(SET_SOURCE_APP, payload);
}

export function setUTMObjectStore({ commit }, payload) {
  commit(SET_UTM_OBJECT, payload);
}
