import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { replayIntegration } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002F8f05ae27505d48cfaacb435b606a2725@o1078615.ingest.sentry.io\u002F6082630",
    environment:"production",
    ignoreErrors:["ReportingObserver [csp-violation]: No details available","Can't find variable: __AutoFillPopupClose__","Failed to fetch","Load failed","Can't find variable: AddShoppersWidget","Cannot read properties of undefined (reading 'domInteractive')","Cannot read property 'domInteractive' of undefined","null is not an object (evaluating 'e.contentWindow.postMessage')","document.getElementsByClassName.ToString","Can't find variable: _AutofillCallbackHandler","Can't find variable: IFrameMessageHandler"],
    denyUrls:[new RegExp("extensions\\\u002F", "i"),new RegExp("^chrome:\\\u002F\\\u002F", "i"),new RegExp("127\\.0\\.0\\.1:4001\\\u002Fisrunning", "i"),new RegExp("\\\u002F(widget)\\.js", "i"),new RegExp("ct\\.pinterest\\.com", "i"),new RegExp("t\\.reverse\\.health", "i"),new RegExp("shopper\\.shop\\.pe", "i"),new RegExp("static\\.hotjar\\.com", "i")],
    replaysSessionSampleRate:0.01,
    replaysOnErrorSampleRate:1,
    tracesSampleRate:0.1,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
    replayIntegration({ maskAllText:true }),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true}, {"tracesSampleRate":0.1})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
