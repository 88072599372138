import parsePhoneNumberFromString from 'libphonenumber-js';

export const getShippingInfo = (state) => state.shippingInfo;

export const getUserPhoneNumber = (state) => {
  const phone = state.shippingInfo.cellPhone.countryCode + state.shippingInfo.cellPhone.phoneNumber;
  return parsePhoneNumberFromString(phone)?.number;
};

export const getUserDataForIVIM = (state, getters, rootState, rootGetters) => {
  const safeValue = (value) => value ?? '';
  const { firstName, lastName, streetAddress: address1, city, zipCode: zip, state: userState } = state.shippingInfo;
  const email = rootGetters['payment/getUserEmail'];
  const uuid = rootGetters['have-lead/getLeadUUID'] ?? '';
  const userInfo = rootGetters['quiz/getUserInfo'];
  if (!userInfo) return;
  const { heightFt, heightInch: heightIn, weightLbs: weightLb, dob: dateOfBirth } = userInfo.answer;
  const getQuizAnswer = (id) => rootGetters['quiz/getAnswerById'](id);
  const insuranceType = getQuizAnswer('type-of-insurance');

  // History Mapping:
  const historyMap = {
    'pancreatitis-gastroparesis-history': ['Pancreatitis', 'Gastroparesis'],
    'thyroid-men-2-syndrome': ['Medullary thyroid cancer', 'MEN-2 syndrome'],
    'glp-1-medications-allergy': ['Allergy to GLP-1 agonist'],
  };

  const personalHistory = new Set();
  const familyHistory = new Set();
  for (const [quizId, historyItems] of Object.entries(historyMap)) {
    if (getQuizAnswer(quizId) === 'yes') {
      historyItems.forEach((item) => personalHistory.add(item));
      if (quizId === 'thyroid-men-2-syndrome') {
        // special case
        historyItems.forEach((item) => familyHistory.add(item));
      }
    }
  }
  const medsPregnancyAnswer = getQuizAnswer('meds-pregnancy');
  if (medsPregnancyAnswer === 'currently-breastfeeding') {
    personalHistory.add('Currently breastfeeding');
  } else if (medsPregnancyAnswer === 'currently-pregnant') {
    personalHistory.add('Currently pregnant');
  }
  const patientDemographics = {
    firstName: safeValue(firstName),
    lastName: safeValue(lastName),
    email: safeValue(email),
    cellPhone: safeValue(getters.getUserPhoneNumber),
    address1: safeValue(address1),
    city: safeValue(city),
    state: safeValue(userState),
    zip: safeValue(zip),
    gender: 'Female',
    dateOfBirth: safeValue(dateOfBirth),
  };
  const patientQualifications = {
    heightFt: safeValue(heightFt),
    heightIn: safeValue(heightIn),
    weightLb: safeValue(weightLb),
    insuranceType: safeValue(insuranceType),
    personalHistory: [...personalHistory],
    familyHistory: [...familyHistory],
  };
  return {
    uuid: safeValue(uuid),
    ivimData: {
      patientQualifications,
      patientDemographics,
    },
  };
};

export const getIvimAccountExists = (state) => state.alreadyExistingIvimAccount;
